<template>
  <va-card title="Form Submit">
    <div class="mb-3">
      <h2 class="display-4 mb-1">ID</h2>
      <span>{{ id }}</span>
    </div>

    <div class="mb-3">
      <h2 class="display-4 mb-1">Name</h2>
      <span>{{ name }}</span>
    </div>

    <div class="mb-3">
      <h2 class="display-4 mb-1">Email</h2>
      <span>{{ mail }}</span>
    </div>

    <div class="mb-3">
      <h2 class="display-4 mb-1">Phone</h2>
      <span>{{ phone }}</span>
    </div>

    <div v-if="comments" class="mb-3">
      <h2 class="display-4 mb-1">Comments</h2>
      <span>{{ comments }}</span>
    </div>

    <div class="mb-3">
      <h2 class="display-4 mb-1">Source</h2>
      <a :href="source">{{ source }}</a>
    </div>

    <div class="mb-3" v-if="created_at">
      <h2 class="display-4 mb-1">Created at</h2>
      <span>{{ created_at }}</span>
    </div>
  </va-card>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  data () {
    return {
      id: '',
      name: '',
      mail: '',
      phone: '',
      comments: '',
      source: '',
      created_at: '',
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    fetch () {
      axios.get(`${process.env.VUE_APP_1LK_API_URL}/admin/front-form/${this.$attrs.id}?lang=${this.locale}`)
        .then(response => {
          this.id = this.$attrs.id
          this.name = response.data.name
          this.mail = response.data.mail
          this.phone = response.data.phone
          this.comments = response.data.comments
          this.source = response.data.source
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
</style>
